require('./bootstrap');
window.Vue = require('vue');
import {createApp} from 'vue'
import mainapp from './components/mainapp.vue';
import router from './router'
import { vMaska } from "maska"
const app=createApp(mainapp)
// app.component('App',App).default;
app.component('mainapp',require('./components/mainapp.vue').default);
createApp(mainapp).directive("maska", vMaska).use(router).mount('#app');

