<template>
    <section>
        <!-- Carousel Start -->
        <div
            v-if="$route.name == 'AboutUs'"
            class="container-fluid p-0 mb-5 wow fadeIn main-bar"
            data-wow-delay="0.1s"
        >
            <div
                id="header-carousel"
                class="carousel slide carousel-fade"
                data-bs-ride="carousel"
            >
                <div
                    class="main-banner"
                    style="background-image: url('img/about-bg.png')"
                >
                    <div class="hd-main">
                        <div class="container">
                            <h3>About Us</h3>
                            <p>
                                GreenAI solves the problems of an outdated and
                                inefficient agriculture system by creating a
                                comprehensive precision agriculture solution for
                                large-scale outdoor and indoor farming,
                                optimized for Pakistan's environment.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Carousel End -->

        <!-- About Start -->
        <div v-if="$route.name == 'home'" class="container-xxl py-5">
            <div class="container">
                <div class="row g-4 align-items-end- mb-4">
                    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <img class="img-fluid" src="img/about.png" />
                    </div>
                    <div
                        class="col-lg-6 wow fadeInUp overwiew display-center"
                        data-wow-delay="0.3s"
                    >
                        <h3 class="display-5 mb-4 hd-t">Project GreenAI</h3>
                        <p>
                            GreenAI solves the problems of an outdated and
                            inefficient agriculture system by creating a
                            comprehensive precision agriculture solution for
                            large-scale outdoor and indoor farming, optimized
                            for Pakistan's environment.<br /><br />

                            GreenAI takes a multi-pronged and phase-wise
                            approach towards Precision Agriculture, deploying
                            Unmanned Aerial Vehicles (UAVs) and Drones for large
                            scale outdoor farming at one end to developing small
                            cabinet size in-door controlled farming units at the
                            other. The indoor units (GreenCabins) shall also
                            serve as a controlled environment for robust
                            development of AI algorithms to monitor & detect
                            plant species specific conditions which will lead to
                            improved performance of smart sensors in the outdoor
                            environment as well.
                            <!--<span> Read more </span>-->
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- About End -->

        <!-- Facts Start -->
        <PartnerComponent v-if="$route.name == 'AboutUs'"></PartnerComponent>
        <!-- Facts End -->

        <!--  Start -->
        <MinistryComponent v-if="$route.name == 'AboutUs'"></MinistryComponent>
        <!--  End -->

        <!--  Start -->
        <BecomePartnetComponent
            v-if="$route.name == 'AboutUs'"
        ></BecomePartnetComponent>
        <!--  End -->
        <!--  Start -->
        <div
            v-if="$route.name == 'AboutUs'"
            class="container-fluid my-5 py-5"
            style="
                background: linear-gradient(140deg, #008e9b 50%, #008f7a 100%);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            "
        >
            <div class="container">
                <div class="row g-5 align-items-center">
                    <div class="col-lg-7 wow fadeInUp" data-wow-delay="0.1s">
                        <h3 class="display-5 mb-4 hd-t text-white">
                            Project Director’s Message
                        </h3>
                        <p class="sec-p pb-3 text-white">
                            “Our company’s strongest trait is commitment. We are
                            committed to our clients & our team members. It is a
                            quality that all the best teams and their members
                            must possess.We demand excellence, deliver our
                            promises and continuously search for new and better
                            ways to provide the best solutions for our
                            customers.We have hands-on management philosophy,
                            keen team players and pride ourselves on quality
                            works, workmanship and the timely completion of the
                            projects depending on the nature and duration of the
                            contracts. We have vast amount of experiences and
                            capable of handling wide diversity.”
                        </p>
                        <h4 class="text-white">Dr. Tauseef ur Rehman</h4>
                    </div>
                    <div class="col-lg-4 ms-5 pmd">
                        <div class="row g-4 align-items-center">
                            <img src="img/pmd.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--  End -->
        <!--  Start -->
        <div
            v-if="$route.name == 'AboutUs'"
            class="container-xxl service py-5"
            style="background-color: #f8fbff"
        >
            <div class="container">
                <div
                    class="text-center mx-auto wow fadeInUp"
                    data-wow-delay="0.1s"
                >
                    <h3 class="display-5 mb-4 hd-t">Meet Our Great Team</h3>
                    <div class="bor-bottom"></div>
                </div>
                <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div
                        class="col-lg-4"
                        v-for="team in ourTeam"
                        :key="team.id"
                    >
                        <div class="main-team">
                            <a
                                :href="team.team_images.url"
                                data-lightbox="1"
                                :data-title="team.name"
                            >
                                <img
                                    class="c-icon"
                                    :src="team.team_images.url"
                                    alt=""
                                />
                            </a>
                            <h3>{{ team.name }}</h3>
                            <p>{{ team.designation }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--  End -->
        <!--  Start -->
        <div v-if="$route.name == 'AboutUs'" class="container-xxl service py-5">
            <div class="container">
                <div
                    class="text-center mx-auto wow fadeInUp"
                    data-wow-delay="0.1s"
                >
                    <h3 class="display-5 mb-4 hd-t">Development Partners</h3>
                    <div class="bor-bottom"></div>
                </div>
                <div class="row g-4 mt-3 wow fadeInUp" data-wow-delay="0.3s">
                    <div
                        class="col-lg-3"
                        v-for="dp in developmentPartners"
                        :key="dp.id"
                    >
                        <div class="main-partner">
                            <a :href="dp.partners_images.url" data-lightbox="2">
                                <img
                                    v-bind:src="dp.partners_images.url"
                                    class="c-icon"
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mt-5">
                <div
                    class="text-center mx-auto wow fadeInUp"
                    data-wow-delay="0.1s"
                >
                    <h3 class="display-5 mb-4 hd-t">Supply Chain Partners</h3>
                    <div class="bor-bottom"></div>
                </div>
                <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                    <!-- <div class="row owl-carousel owl-theme owl-carousel-Chain"> -->
                        <div
                            class="col-lg-3"
                            v-for="scp in supplyChainPartners"
                            :key="scp.id"
                        >
                            <div class="main-partner">
                                <a
                                    :href="scp.partners_images.url"
                                    data-lightbox="3"
                                >
                                    <img
                                        v-bind:src="scp.partners_images.url"
                                        class="c-icon"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    <!-- </div> -->
                </div>
            </div>
            <div class="container mt-5">
                <div
                    class="text-center mx-auto wow fadeInUp"
                    data-wow-delay="0.1s"
                >
                    <h3 class="display-5 mb-4 hd-t">Partners on Radar</h3>
                    <div class="bor-bottom"></div>
                </div>
                <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                    <!-- <div class="row owl-carousel owl-theme owl-carousel-Chain"> -->
                        <div
                            class="col-lg-3"
                            v-for="rp in radarPartners"
                            :key="rp.id"
                        >
                            <div class="main-partner">
                                <a
                                    :href="rp.partners_images.url"
                                    data-lightbox="4"
                                >
                                    <img
                                        v-bind:src="rp.partners_images.url"
                                        class="c-icon"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <!--  End -->
    </section>
</template>

<script>
import PartnerComponent from "./PartnerComponent.vue";
import MinistryComponent from "./MinistryComponent.vue";
import BecomePartnetComponent from "./BPartnerComponent.vue";
export default {
    data() {
        return {
            developmentPartners: [],
            supplyChainPartners: [],
            radarPartners: [],
            ourTeam: [],
            baseUrl: Laravel.BaseURL,
        };
    },
    components: {
        PartnerComponent,
        MinistryComponent,
        BecomePartnetComponent,
    },
    created() {
        //console.log("inside create");
        axios
            .get(this.baseUrl + "/get_development_partner")
            .then((res) => {
                this.developmentPartners = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

        axios
            .get(this.baseUrl + "/get_supply_chain_partner")
            .then((res) => {
                this.supplyChainPartners = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

        axios
            .get(this.baseUrl + "/get_partner_on_redar")
            .then((res) => {
                this.radarPartners = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

        axios
            .get(this.baseUrl + "/OurTeam")
            .then((res) => {
                this.ourTeam = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    },
    mounted() {
        //console.log(this.base_url);
        $(".owl-carousel-Chain").owlCarousel({
            loop: true,
            margin: 10,
            nav: false,
            responsive: {
                //   0:{
                //       items:1
                //   },
                //   600:{
                //       items:2
                //   },
                //   1000:{
                //       items:4
                //   },
                //   0:{
                //       items:5
                //   },
                //   600:{
                //       items:6
                //   },
                //   1000:{
                //       items:7
                //   },
                //   1000:{
                //       items:8
                //   },
                //   1000:{
                //       items:9
                //   },
                //   1000:{
                //       items:10
                //   },
                //   1000:{
                //       items:11
                //   },
                //   1000:{
                //       items:12
                //   },
                //   1000:{
                //       items:13
                //   }
            },
        });
    },
};
</script>
