<template>
    <div class="container" style="margin-top:20px">
  <form @submit.prevent="updateConsortiumPartner" enctype="multipart/form-data">
    <div class="custom-file pmd-custom-file-outline col-md-10">
      <input ref="fileInput" type="file" name="image" class="custom-file-input" id="customOutlineFile" @change="handleFileChange">
    </div>
    <div id="selectedFileName" v-if="selectedFileName=='Choose file'">{{ form.image }}</div>
    <br>
       <br><br>
    <div class="form-row">
      <div class="form-group pmd-textfield pmd-textfield-floating-label col-md-10">
        <input v-model="form.title" type="text" name="title" id="form-layout-address1" class="form-control">
      </div>
    </div><br>
      <div class="pmd-textfield pmd-textfield-filled pl-0 col-md-10">
        <textarea v-model="form.description" rows="5" name="description" class="form-control"></textarea>
      </div>
    <br>
    <button type="submit" class="btn pmd-ripple-effect btn-primary col-md-10 mt-2">Update</button>
  </form></div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                image: null,
                title: '',
                description: ''
                            },

            selectedFileName: 'Choose file',
            currentImageSrc: 'http://project.green_ai/storage/uploads/' // Set the URL of the current image here
        };
     
    },
   mounted() {
    // Fetch data when the component is mounted
    const id = this.$route.params.id;
       this.fetchData(id);
         
  },
    methods: {
    async fetchData(id) {
      axios.get(`http://project.green_ai/admin/ConsortiumPartner/edit/${id}`)
        .then(response => {
          this.form=response.data;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
      },
      updateConsortiumPartner() {
         const formData = new FormData();
      formData.append('title', this.form.title);
      formData.append('description', this.form.description);
    if (this.form.image) {
        formData.append('image', this.form.image); // Append the image with its name
        }
        axios.post(`http://project.green_ai/admin/ConsortiumPartner/update/${this.$route.params.id}`, formData, {
          headers: {
          'Content-Type': 'multipart/form-data'
        }
        }).then(res => {
       this.successMessage = res.data.message;
           this.$router.push({ name: 'partnersIndex' });
        })
        .catch(error => {
          console.error('Error updating status:', error);
        });
      
    },
    handleFileChange(event) {
      this.form.image = event.target.files[0];
      this.selectedFileName = event.target.files[0].name;
            this.form.image = file;

      }
    
  }
};
</script>

<style>
/* Component styles */
</style>
