<template>
  <div class='py-12'>
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                <div class="p-6 bg-white border-b border-grey-200" style="margin:10px">
                        <div class="container-lg">
                            <div class="table-responsive">
                                <div class="table-wrapper">
                                    <div class="table-title">
                                        <div class="row"  style="padding:10px">
                                            <div class="col-sm-8" >
                                                <h2>Consortium <b>Partners</b></h2>
                                            </div>
                                   <div class="col-sm-4">
                                          <router-link to="/CreatePartners" class="btn btn-info">
                                        <i class="fa fa-plus"></i> Add Consortium Partners
                                                </router-link>
                                        </div>
                                        </div>
                                    </div>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th>Picture</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                                <tr v-for="consortiumPartner in consortiumPartners" :key="consortiumPartner.id">
                                                    <td>{{ consortiumPartner.title }}</td>
                                                    <td>{{ consortiumPartner.description }}</td>
                                                   <td>
                                                    <img :src="getImageUrl(consortiumPartner.image)"
                                                     alt="image not found" style="width:50px;height:50px">

                                                    </td>
                                                    <td style="display: flex" class="p-3">
                                                        <a class="m-1" :href="getEditUrl(consortiumPartner.id)" title="Edit"  @click.prevent="editConsortiumPartner(consortiumPartner.id)">
                                                            <button class="btn btn-success">Edit</button>
                                                        </a>
                                                        <form class="m-1" @submit="deleteConsortiumPartner" v-if="confirmDelete">
                                                            <button type="submit" class="btn btn-danger">Delete</button>
                                                        </form>
                                                        </td>
                                                </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
    </div>
</template>

<script>
export default {
props: ['consortiumPartner'],
  data() {
    
      return {
           baseUrl:'http://project.green_ai/storage/uploads/',
          confirmDelete: true,
          consortiumPartners:[]
    };
    },
  created() {
      this.getConsortiumPartners();
  },
    methods: {

         async getConsortiumPartners() {
            axios.get(`http://project.green_ai/admin/ConsortiumPartner#/`).then((res) => {
                this.consortiumPartners = res.data;
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
      },
        editConsortiumPartner(id) {
            this.$router.push({ name: 'EditPartners', params: { id: id } });
    },
 getImageUrl(imagePath) {
        return this.baseUrl + imagePath;
    },
      getEditUrl(id) {
    },
    deleteConsortiumPartner() {
      if (confirm('Are you sure you want to delete?')) {
        // Logic to delete consortium partner
        // For example: call an API or trigger a delete action
      }
    }
  }
}
</script>

<style>

</style>