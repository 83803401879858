<template>
    <section>
        <!-- Footer Start -->
        <div
            class="container-fluid text-light footer mt-5 pt-5 wow fadeIn"
            data-wow-delay="0.1s"
        >
            <div class="container pt-5">
                <div class="row g-5 mt-5 pt-3">
                    <div class="col-lg-5 col-md-6">
                        <p class="mb-2">Email</p>
                        <h4 class="text-white mb-4">info@greenai.org.pk</h4>
                        <p>Location</p>
                        <p class="mb-2">
                            Building Alpha-3 National Aerospace Science and
                            Technology Park (NASTP) Old Airport Road, Rawalpindi
                        </p>
                    </div>
                    <div class="col-lg-7 col-md-6">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <h4 class="text-white mb-2">Partners</h4>
                                <a
                                    class="btn btn-link"
                                    target="_blank"
                                    href="https://nastp.gov.pk/"
                                    >NASTP</a
                                >
                                <a
                                    class="btn btn-link"
                                    target="_blank"
                                    href="https://www.pcsir.gov.pk/"
                                    >PCSIR</a
                                >
                                <a
                                    class="btn btn-link"
                                    target="_blank"
                                    href="https://www.uaar.edu.pk/"
                                    >ARID</a
                                >
                                <a
                                    class="btn btn-link"
                                    target="_blank"
                                    href="https://mod.gov.pk/"
                                    >Ministry of Defense</a
                                >
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <h4 class="text-white mb-2">Sitemap</h4>
                                <router-link
                                    to="/ProjectComponents"
                                    class="btn btn-link"
                                    >Projects</router-link
                                >
                                <router-link to="/Jobs" class="btn btn-link"
                                    >Jobs</router-link
                                >
                                <router-link
                                    to="/Publications"
                                    class="btn btn-link"
                                    >Publications</router-link
                                >
                                <router-link to="/News" class="btn btn-link"
                                    >News & Events</router-link
                                >
                                <router-link to="/FAQs" class="btn btn-link"
                                    >FAQ’s</router-link
                                >
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <h4 class="text-white mb-2">Contact</h4>
                                <router-link to="/AboutUs" class="btn btn-link"
                                    >About Us</router-link
                                >
                                <router-link to="/Contact" class="btn btn-link"
                                    >Contact Us</router-link
                                >
                                <a
                                    class="btn btn-link"
                                    target="_blank"
                                    href="https://www.ppra.org.pk/Rules.asp"
                                    >PPRA Rules</a
                                >
                                <a
                                    class="btn btn-link"
                                    target="_blank"
                                    href="http://nastpalpha.org:4049"
                                    >Open Project</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-5 mt-3 pb-3">
                    <div class="col-lg-5 col-md-6">
                    <router-link to="/" class="nav-item nav-link" >
                        <img
                            class="footer-logo"
                            src="img/footer-logo.png"
                            alt=""
                        /></router-link>
                        <p class="mb-2">
                            <span class="footer-small"
                                >Copyright © 2024 GreenAI. All Rights
                                Reserved.</span
                            >
                        </p>
                    </div>
                    <div class="col-lg-7 col-md-6">
                        <div class="row">
                            <div class="col-lg-8 col-md-6">
                                <h4 class="text-white mb-2">Connect us</h4>
                                <div class="d-flex pt-2">
                                    <!-- <a
                                        class="btn btn-square btn-outline-light rounded-circle me-2"
                                        href=""
                                        ><i class="fab fa-facebook-f"></i
                                    ></a>
                                    <a
                                        class="btn btn-square btn-outline-light rounded-circle me-2"
                                        href=""
                                        ><i class="fab fa-twitter"></i
                                    ></a>
                                    <a
                                        class="btn btn-square btn-outline-light rounded-circle me-2"
                                        href=""
                                        ><i class="fab fa-pinterest-p"></i
                                    ></a> -->
                                    <a
                                        class="btn btn-square btn-outline-light rounded-circle me-2"
                                        href="https://www.linkedin.com/company/greenaiorgpk/"
                                        target="_new"
                                        ><i class="fab fa-linkedin"></i
                                    ></a>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <h4 class="text-white mb-2">Help Center</h4>
                                <p>
                                    <span class="footer-small"
                                        >help@greenai.com</span
                                    >
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer End -->

        <!-- Back to Top -->
        <a
            href="#"
            class="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"
            ><i class="bi bi-arrow-up"></i
        ></a>
    </section>
</template>
<script>
// (function() {
// $(document).on("click", ".click-to-expand", function() {
// var imageSrc = $(this).parents(".image-grid").find("img").attr("src");
// $(".js-modal-image").attr("src", imageSrc);
// });
// })();
// const items = document.querySelectorAll('.accordion button');
// function toggleAccordion() {
//   const itemToggle = this.getAttribute('aria-expanded');

//   for (i = 0; i < items.length; i++) {
//     items[i].setAttribute('aria-expanded', 'false');
//   }

//   if (itemToggle == 'false') {
//     this.setAttribute('aria-expanded', 'true');
//   }
// }

// items.forEach((item) => item.addEventListener('click', toggleAccordion));
</script>
