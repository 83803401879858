<template>
    <section>
        <!--  Start -->
        <div class="container-fluid mb-5 mt-5 py-5">
            <div class="container">
                <div class="row g-4 align-items-end- mb-4">
                    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <img class="img-fluid" src="img/about1.jpg" />
                    </div>
                    <div
                        class="col-lg-6 wow fadeInUp overwiew display-center"
                        data-wow-delay="0.3s"
                    >
                        <h3 class="display-5 mb-4 hd-t Sponsoring">
                            Sponsoring Ministries of Government of Pakistan
                        </h3>
                        <ul class="Sponsoring-ul">
                            <li>
                                <h4>
                                    Ministry of Planning Development and Special
                                    Initiatives
                                </h4>
                                <p class="mb-4">Government of Pakistan</p>
                            </li>
                            <li>
                                <h4>Ministry of Defence</h4>
                                <p class="mb-4">Government of Pakistan</p>
                            </li>
                            <li>
                                <h4>
                                    Ministry of National Food Security &
                                    Research
                                </h4>
                                <p class="mb-4">Government of Pakistan</p>
                            </li>
                            <li>
                                <h4>Ministry of Science & Technology</h4>
                                <p>Government of Pakistan</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!--  End -->

        <!--  Start -->
        <div class="container-fluid my-5 item-bg"></div>
        <!--  End -->
    </section>
</template>
