
<template>
    <div class="app">
        <NavigationComponent></NavigationComponent>
        <router-view></router-view>
        <FooterComponent></FooterComponent>
    </div>
</template>
<script>
import NavigationComponent from './common/NavigationComponent.vue';
import FooterComponent from "./common/FooterComponent.vue";
export default{
    mounted(){
    //console.log($route.name);
    },
    components:{
        NavigationComponent,
        FooterComponent
    }
}
</script>