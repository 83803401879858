<template>
    <section>
        <!-- Carousel Start -->
        <div class="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
            <div
                id="header-carousel"
                class="carousel slide carousel-fade"
                data-bs-ride="carousel"
            >
                <div class="carousel-inner">
                    <div
                        class="carousel-item"
                        v-for="(slider, i) in home_slider"
                        :key="slider.id"
                        :class="i == 0 ? 'active' : ''"
                    >
                        <img
                            class="w-100"
                            style="height:670px"
                            :src="slider.gallery_images.url"
                            alt="Image"
                        />
                        <div class="carousel-caption">
                            <div class="container">
                                <div
                                    class="row justify-content-start align-items-center"
                                >
                                    <div class="col-lg-6">
                                        <h3 class="py-3 animated slideInDown">
                                            Artificial Intelligence
                                        </h3>
                                        <p class="mb-5 animated slideInDown">
                                            ICT and Artificial Intelligence (AI)
                                            Based Precision Agriculture Systems
                                            Utilizing Aerospace Technologies
                                        </p>
                                        <div
                                            class="position-relative w-100 newsCls"
                                        >
                                            <input :disabled="subscribe_disable"
                                                class="form-control bg-white border-0 w-100 Newsletter"
                                                type="email"
                                                placeholder="Email address"
                                                ref="newsletterinput"
                                                v-model="newsletter_value"
                                            />
                                            <button
                                            :disabled="subscribe_disable"
                                                type="submit"
                                                class="btn btn-primary position-absolute Subscribe-btn"
                                                @click="newsletter_reg(i)"
                                            >
                                                Subscribe Newsletter
                                            </button>
                                            <!-- <p class="msg_status"></p> -->
                                            <p
                                                :class="
                                                    error_flag
                                                        ? 'alert-danger'
                                                        : 'alert-success'
                                                "
                                            >
                                                {{ sent_status }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="carousel-item">
                    <img class="w-100" src="img/carousel-12.png" alt="Image">
                    <div class="carousel-caption">
                        <div class="container">
                            <div class="row justify-content-start align-items-center">
                                <div class="col-lg-6">                             
                                        <h3 class="py-3 animated slideInDown">Artificial Intelligence</h3>
                                        <p class="mb-5 animated slideInDown">ICT and Artificial Intelligence (AI) Based Precision Agriculture Systems Utilizing Aerospace Technologies
                                        </p>
                                        <div class="position-relative w-100">
                                            <input class="form-control bg-white border-0 w-100 Newsletter" type="text"
                                                placeholder="Email address">
                                            <button type="button"
                                                class="btn btn-primary position-absolute Subscribe-btn">Subscribe Newsletter</button>
                                        </div>
                                </div>
                             
                            </div>
                        </div>
                    </div>
                </div> -->
                </div>
                <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#header-carousel"
                    data-bs-slide="prev"
                >
                    <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#header-carousel"
                    data-bs-slide="next"
                >
                    <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
        <!-- Carousel End -->
    </section>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            home_slider: [],
            baseUrl: Laravel.BaseURL,
            newsletter_value: "",
            sent_status: "",
            error_flag: false,
            subscribe_disable:false
        };
    },
    created() {
        axios
            .get(this.baseUrl + "/get_home_slider")
            .then((res) => {
                this.home_slider = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    },
    methods: {
        newsletter_reg(index_ar) {
            this.subscribe_disable = true;
            //const prevElementValue = this.$refs.newsletterinput[index_ar].value;
            axios
                .post(this.baseUrl + "/api/newsLetterReg", {
                    inputValue: this.newsletter_value,
                })
                .then((response) => {
                    if (response.data.errors) {
                        this.error_flag = true;
                        this.sent_status = response.data.errors;
                        setInterval(() => {
                            //this.sent_status = "";
                            this.subscribe_disable = false;
                        }, 6000);
                    } else {
                        this.error_flag = false;
                        this.sent_status = response.data.success;
                        setInterval(() => {
                            //this.newsletter_value = "";
                            this.sent_status = "";
                            this.subscribe_disable = false;
                        }, 6000);
                    }
                })
                .catch((error) => {
                    this.error_flag = true;
                    this.sent_status = error.response.data.errors.email[0];
                    setInterval(() => {
                        //this.sent_status = "";
                        this.subscribe_disable = false;
                    }, 6000);
                });
        },
    },
};
</script>
