<template>
    <section>
        <!-- Carousel Start -->
        <div
            class="container-fluid p-0 mb-5 wow fadeIn main-bar"
            data-wow-delay="0.1s"
        >
            <div
                id="header-carousel"
                class="carousel slide carousel-fade"
                data-bs-ride="carousel"
            >
                <div
                    class="main-banner"
                    style="
                        background-image: url('img/contact-bg.png');
                        background-size: cover;
                    "
                >
                    <div class="hd-main">
                        <div class="container">
                            <h3>
                                Any questions?<br />
                                Contact us.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Carousel End -->
        <!-- Service Start -->
        <div class="container-xxl service">
            <div class="container">
                <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="hd-main-form">
                        <div class="">
                            <div
                                class="border rounded p-4 p-sm-5 wow fadeInUp Message-body"
                                data-wow-delay="0.5s"
                                style="
                                    visibility: visible;
                                    animation-delay: 0.5s;
                                    animation-name: fadeInUp;
                                    background: #fff;
                                    box-shadow: 0px 0px 20px 0px
                                        rgba(0, 0, 0, 0.1);
                                    border-radius: 0rem 0rem 0.625rem 0.625rem !important;
                                "
                            >
                                <form @submit.prevent="sendEmail()">
                                    <div class="row g-3">
                                        <div class="col-sm-12">
                                            <div class="form-floating">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="name"
                                                    v-model="formFields.name"
                                                    placeholder="Your Name"
                                                />
                                                <label for="name">Name</label>
                                                <span
                                                    v-if="errors.name"
                                                    class="alert-danger"
                                                    >{{ errors.name[0] }}</span
                                                >
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-floating">
                                                <input
                                                    type="email"
                                                    class="form-control"
                                                    id="email"
                                                    v-model="formFields.email"
                                                    placeholder="Your Email"
                                                />
                                                <label for="email">Email</label>
                                                <span
                                                    v-if="errors.email"
                                                    class="alert-danger"
                                                    >{{ errors.email[0] }}</span
                                                >
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-floating">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="formFields.subject"
                                                    id="subject"
                                                    placeholder="Subject"
                                                />
                                                <label for="subject"
                                                    >Subject</label
                                                >
                                                <span
                                                    v-if="errors.subject"
                                                    class="alert-danger"
                                                    >{{
                                                        errors.subject[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-floating">
                                                <textarea
                                                    class="form-control"
                                                    placeholder="Leave a message here"
                                                    v-model="
                                                        formFields.message_content
                                                    "
                                                    id="message_content"
                                                    style="height: 100px"
                                                ></textarea>
                                                <label for="message"
                                                    >Message</label
                                                >
                                                <span
                                                    v-if="
                                                        errors.message_content
                                                    "
                                                    class="alert-danger"
                                                    >{{
                                                        errors
                                                            .message_content[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                        <div class="col-12 mt-5">
                                            <button
                                                class="btn py-2 btn-primary w-100"
                                                type="submit"
                                                :disabled="formSubmitting"
                                            >
                                                {{ submitButtonText }}
                                            </button>
                                            <p
                                                :class="
                                                    error_flag
                                                        ? 'alert-danger'
                                                        : 'alert-success'
                                                "
                                            >
                                                {{ sent_status }}
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-md-6 d-flex justify-content-center align-items-center"
                    >
                        <ul class="address">
                            <li>
                                <img src="img/map.png" alt="" />
                                <p>
                                    Building Alpha-3 National Aerospace Science
                                    and Technology Park (NASTP) Old Airport
                                    Road, Rawalpindi
                                </p>
                            </li>
                            <li>
                                <img src="img/phone.png" alt="" />
                                <p>051-9529543</p>
                            </li>
                            <li>
                                <img src="img/mail.png" alt="" />
                                <p>info@greenai.org.pk</p>
                            </li>
                            <li>
                                <img src="img/time.png" alt="" />
                                <p>
                                    Monday — Friday<br />
                                    09:00 AM — 05:00 PM
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Service End -->
    </section>
</template>

<script>
export default {
    data() {
        return {
            formFields: {
                name: "",
                email: "",
                subject: "",
                message_content: "",
            },
            baseUrl: Laravel.BaseURL,
            formSubmitting: false,
            submitButtonText: "Send Email",
            sent_status: "",
            errors: {},
            error_flag : false,
        };
    },
    methods: {
        sendEmail() {
            this.formSubmitting = true;
            this.submitButtonText = "Sending...";
            let formData = new FormData();
            formData = this.customAppendFormData(formData);
            this.sent_status="";
            axios
                .post(this.baseUrl + "/api/sendEmail", formData)
                .then((response) => {
                    if (response.data.errors) {
                        this.formSubmitting = false;
                        this.submitButtonText = "Send Email";
                        this.errors = {};
                        this.sent_status = response.data.errors;
                        this.error_flag = true;
                    } else {
                        this.formFields.name = "";
                        this.formFields.email = "";
                        this.formFields.subject = "";
                        this.formFields.message_content = "";
                        this.formSubmitting = true;
                        this.error_flag = false;
                        this.sent_status = "";
                        this.errors = {};
                        setInterval(() => {
                            this.formSubmitting = false;
                            this.submitButtonText = "Send Email";
                            this.sent_status = response.data.success;
                        }, 6000);
                    }
                })
                .catch((error) => {
                    this.formSubmitting = false;
                    this.submitButtonText = "Send Email";
                    this.sent_status = error;
                    this.errors = error.response.data.errors;
                    this.error_flag = true;
                    //console.log(this.errors);
                });
        },
        customAppendFormData(formData) {
            let aFields = Object.keys(this.formFields);
            aFields.forEach(function (field, index) {
                formData.append(
                    field,
                    this.formFields[field] ? this.formFields[field] : ""
                );
            }, this);
            return formData;
        },
    },
};
</script>
