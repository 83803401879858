<template>
    <section>
        <!--  Start -->
        <div class="container-xxl py-5">
            <div class="container">
                <div class="row g-4 align-items-end- mb-4">
                    <div
                        class="col-lg-6 wow fadeInUp Hiring display-center"
                        data-wow-delay="0.3s"
                    >
                        <h3 class="display-5 mb-4 hd-t">
                            Become a GreenAI Partner
                        </h3>
                        <p class="sec-p pb-3">
                            Project GreenAI is reaching out to the private tech
                            industry in Pakistan to partner and joining hands in
                            developing key technologies and solutions envisioned
                            in the project. If you are a tech company working in
                            any of the related technology areas or Agri-tech
                            vertical in particular, please feel free to mail us
                            your brief profile and contact details at
                            info@greenai.org.pk. We will put you on mailing list
                            for updates on upcoming opportunities and events.
                        </p>
                        <router-link to="/Contact" class="btn btn-primary px-4 animated slideInDown apply-btn" >Become Partner</router-link>
                        <!-- <a
                            href=""
                            class="btn btn-primary px-4 animated slideInDown apply-btn"
                            >Become Partner</a
                        > -->
                    </div>
                    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <img class="img-fluid" src="img/about3.png" />
                    </div>
                </div>
            </div>
        </div>
        <!--  End -->

        <!--  Start -->
        <div class="container-xxl py-5">
            <div class="container">
                <div class="row g-4 align-items-end- mb-4">
                    <div
                        class="col-lg-6 order-lg-2 wow fadeInUp Hiring Hiring2 display-center"
                        data-wow-delay="0.3s"
                    >
                        <h3 class="display-5 mb-4 hd-t">We Are Hiring</h3>
                        <p class="sec-p pb-3">
                            We are continuously seeking suitably qualified,
                            skilled and motivated individuals working in domains
                            of Digital Signal Processing, Aerospace Engineering,
                            Artificial Intelligence, Software Engineering, High
                            Performance Computing, Agriculture Engineering etc
                            If you are interested in pursuing a career in
                            Precision Agriculture space, send us an email with
                            your resume’ at
                            <a href="mailto:info@greenai.org.pk"
                                >info@greenai.org.pk.</a
                            >
                        </p>

                        <router-link to="/Jobs" class="btn btn-primary px-4 animated slideInDown apply-btn" >Apply Now</router-link>
                    </div>
                    <div
                        class="col-lg-6 order-lg-1 wow fadeInUp"
                        data-wow-delay="0.1s"
                    >
                        <img class="img-fluid" src="img/about4.jpg" />
                    </div>
                </div>
            </div>
        </div>
        <!--  End -->
    </section>
</template>
