<template>
  <div>
    <div class="modal fade" data-bs-backdrop='static' tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="btn-close btnClose" data-bs-dismiss="modal" aria-label="Close" @click="closeModal" style="float: right;color: #fff;filter: invert(1);opacity: 1;"></button>
            <div class="onload-video-wrapper">
              <iframe ref="videoFrame" width="100%" height="400" :src="videoUrl" :title="videoUrlTitle"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videoUrl: String,
    videoUrlTitle: String
  },
  methods: {
    closeModal() {
      // Pause the video when modal is closed
      const iframe = this.$refs.videoFrame;
      if (iframe) {
        // Replace the src attribute to stop the video
        iframe.src = iframe.src.replace("?enablejsapi=1", "") + "?autoplay=0";
      }
    }
  }
};
</script>

<style>
/* Add your styles here */
</style>
