<template>
    <section>
        <!-- Facts Start -->
        <div class="container-fluid facts py-5">
            <div class="container py-5">
                <div
                    class="row g-4 wow fadeInUp justify-content-center"
                    data-wow-delay="0.3s"
                >
                    <div
                        class="text-center mx-auto wow fadeInUp"
                        data-wow-delay="0.1s"
                    >
                        <h3 class="display-5 mb-4 hd-t">Consortium Partners</h3>
                        <div class="bor-bottom"></div>
                    </div>
                    <div class="col-lg-9">
                        <div
                            class="nav nav-pills d-flex w-100 me-4"
                        >
                            <button
                                class="Consortium-tab nav-link p-3 px-4 mb-4"
                                :class="product.id == 1 ? 'active' : ''"
                                data-bs-toggle="pill"
                                :data-bs-target="'#tab-pane-' + product.id"
                                type="button"
                                v-for="product in products"
                            :key="product.id"
                            >
                                <h5 class="m-0">{{ product.title }}</h5>
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div
                            class="tab-content w-100"
                            
                        >
                            <div
                                class="Consortium-body tab-pane fade"
                                :id="'tab-pane-' + product.id"
                                :class="product.id == 1 ? 'show active' : ''"
                                v-for="product in products"
                            :key="product.id"
                            >
                                <div class="row g-4">
                                    <div class="col-md-7">
                                        <p class="mb-4">
                                            {{ product.description }}
                                        </p>
                                    </div>
                                    <div class="col-md-5">
                                        <div
                                            class="position-relative text-center"
                                        >
                                            <img
                                            v-bind:src="product.partners_images.url"
                                                class="position-absolute service-main"
                                                alt="product image"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Facts End -->
    </section>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            products: [],
            baseUrl: Laravel.BaseURL,
        };
    },
    created() {
        //console.log("inside create");
        axios
            .get(this.baseUrl+'/get_consortium_partner')
            .then((res) => {
                this.products = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    },
    methods: {
        updateProduct() {
            axios
                .patch(
                    `http://localhost:8000/api/products/${this.$route.params.id}`,
                    this.product
                )
                .then((res) => {
                    this.$router.push({ name: "home" });
                });
        },
    },
};
</script>
