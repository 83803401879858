<template>
    <section>
        <!-- Carousel Start -->
        <div
            v-if="$route.name == 'get_project_components'"
            class="container-fluid p-0 mb-5 wow fadeIn main-bar"
            data-wow-delay="0.1s"
        >
            <div
                id="header-carousel"
                class="carousel slide carousel-fade"
                data-bs-ride="carousel"
            >
                <div class="main-banner">
                    <div class="hd-main">
                        <div class="container">
                            <h3>Project Components</h3>
                            <p>
                                Green AI refers to the use of AI technologies to
                                reduce the environmental impact of human
                                activities. It involves designing and developing
                                algorithms, models, and systems that are
                                environmentally friendly and sustainable.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Carousel End -->

        <!-- Service Start -->
        <div class="container-xxl service py-5">
            <div class="container" style="margin-top: 40px;">
                <div
                    class="text-center mx-auto wow fadeInUp"
                    data-wow-delay="0.1s"
                >
                    <h3 class="display-5 mb-4 hd-t">Project Components</h3>
                    <div class="bor-bottom"></div>
                </div>
                <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div
                        class="col-lg-4"
                        v-for="product in products"
                        :key="product.id"
                    >
                        <div class="main-item">
                                <img 
                               v-if="product.project_components"
                                :src="product.project_components.url"
                                alt="image not found" style="width:50px;height:50px"> 
                            
                            <h3>{{ product.title }}</h3>
                            <p>{{ product.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Service End -->
    </section>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            products: [],
            baseUrl: Laravel.BaseURL,
        };
    },
    created() {
        axios
            .get(this.baseUrl+'/get_project_components')
            .then((res) => {
                this.products = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    },
    methods: {
        updateProduct() {
            axios
                .patch(
                    `http://localhost:8000/api/products/${this.$route.params.id}`,
                    this.product
                )
                .then((res) => {
                    this.$router.push({ name: "home" });
                });
        },
    },
};
</script>
