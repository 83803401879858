import {createRouter,createWebHashHistory} from 'vue-router';
import HomeComponent from '../components/HomeComponent.vue';
import AboutUsComponent from '../components/AboutUsComponent.vue';
import ContactComponent from '../components/ContactComponent.vue';
import CreateProduct from '../components/CreateProduct.vue';
import EditProduct from '../components/EditProduct.vue';
import ProjectComponents from '../components/ProjectComponent.vue'
import JobsComponent from '../components/JobsComponent.vue'
import NewsComponent from '../components/NewsComponent.vue'
import PublicationsComponent from '../components/PublicationsComponent.vue'
import FAQsComponent from '../components/FAQsComponent.vue'
import index from '../components/admin/applied_jobs/index.vue';
import partnersIndex from '../components/admin/consortiumPartners/partnersIndex.vue';
import EditPartners from '../components/admin/consortiumPartners/EditPartners.vue';
import CreatePartners from '../components/admin/consortiumPartners/CreatePartners.vue';
import JobApplyForm from '../components/JobApplyComponent.vue';

const routes = [{
        name: 'home',
        path: '/',
        component: HomeComponent
    },
    {
        name: 'ProjectComponents',
        path: '/ProjectComponents',
        component: ProjectComponents
    },
    {
        name: 'AboutUs',
        path: '/AboutUs',
        component: AboutUsComponent
    },
    {
        name: 'Contact',
        path: '/Contact',
        component: ContactComponent
    },
    {
        name: 'Jobs',
        path: '/Jobs',
        component: JobsComponent
    },
    {
        name: 'JobApply',
        path: '/JobApply/:id',
        component: JobApplyForm
    },
    {
        name: 'News',
        path: '/News',
        component: NewsComponent
    },
    {
        name: 'Publications',
        path: '/Publications',
        component: PublicationsComponent
    },
    {
        name: 'FAQs',
        path: '/FAQs',
        component: FAQsComponent
    },
    {
        name: 'create',
        path: '/create',
        component: CreateProduct
    },
    {
        name: 'edit',
        path: '/edit/:id',
        component: EditProduct
    },
    {
        name: 'appliedJobs',
        path: '/appliedJobs',
        component: index
    },
    {
        name: 'partnersIndex',
        path: '/partnersIndex',
        component: partnersIndex
    },
    {
        name: 'CreatePartners',
        path: '/CreatePartners',
        component: CreatePartners
    },
    {
        name: 'EditPartners',
        path: '/EditPartners/:id',
        component: EditPartners
    },
];
const router=createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
       document.getElementById('app').scrollIntoView({behavior:'smooth'});
    }
});
export default router;