<template>
    <section>

<!-- Carousel Start -->
<div class="container-fluid p-0 mb-5 wow fadeIn main-bar" data-wow-delay="0.1s">
        <div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="main-banner" style="background-image: url('img/job-bg.png');min-height: 400px;background-size: cover;">
                <div class="job-hd">

                    <h3>Find The Job At GreenAi</h3>
                    <p>Resume-Library is a true performance-based job board. Enjoy custom hiring products and access
                        to up to 10,000 new resume registrations daily, with no subscriptions or user licences.
                    </p>
                    <div class="search">
                        <i class="fa fa-search"></i>
                        <input type="text" class="form-control in-search" v-model="search_keyword"
                            placeholder="Job title, key words or company" v-on:keyup="search_job">
                        <!-- <i class="fa fa-map"></i>
                        <input type="text" class="form-control in-map" placeholder="All Location"> -->
                        <button class="btn btn-primary" v-on:click="search_job">Find Jobs</button>
                    </div>

                </div>  
            </div>                     
        </div>
    </div>

    <!-- Carousel End -->

        <!-- Service Start -->
    <div class="container-xxl service py-5">
        <div class="container">
            <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                <!-- <div class="jobs-filter"><i class="fa fa-bars"></i> Job filter</div> -->
                <div class="d-flex align-items-start job-contant">                    
                    <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                        aria-orientation="vertical">
                        <a class="nav-link jobs-tab" :class="i==0 ? 'active':'' " :id="'v-pills-'+i+'-tab'" data-bs-toggle="pill"
                            :data-bs-target="'#v-pills-'+i" role="tab" :aria-controls="'v-pills-'+i"
                            aria-selected="true"
                            v-for="(job,i) in jobObj"
                                :key="job.id"
                            >
                            <div class="jobs-hd-tabs">
                                <img
                                    class="img-fluid"
                                    :src="job.company_logo.url"
                                    alt=""
                                />
                                <div class="details-it">
                                    <p>{{job.job_department }}</p>
                                    <h3>{{job.job_title }}</h3>
                                    <span class="job-lo">{{ job.job_type  }}-{{job.job_area }} <span> {{ job.posting_date   }}</span></span>
                                </div>
                                <div>
                                     <input class="form-check-input" type="checkbox" value="" id="">
                                </div>
                            </div>
                        <div class="job-type">
                            <ul>
                                <li>{{ job.job_type  }}</li>
                                <li class="Urgent">Urgent</li>
                            </ul>
                           <hr>
                           <div class="jobs-amount">
                            <!-- <p>3,50000 - 5,00000<small>/ month</small></p> -->
                            <span class="jobs-date">Deadline: {{ job.deadline_date  }}</span>
                           </div>
                        </div>
                        </a>
                    </div>
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade" :class="ii==0 ? 'show active':''" :id="'v-pills-'+ii" role="tabpanel"
                            :aria-labelledby="'v-pills-'+ii+'-tab'"
                            v-for="(job,ii) in jobObj"
                                :key="job.id"
                            >
                            <div class="w-100 job-content mb-5">
                                <div class="jobs-hd-tabs justify-content-between">
                                    <div class="d-flex">
                                        <img
                                            class="img-fluid"
                                            :src="job.company_logo.url"
                                            alt=""
                                        />
                                        <div class="details-it">
                                            <p>{{job.job_department }}</p>
                                            <h3>{{job.job_title }}</h3>
                                            <span class="job-lo">{{job.job_area }}<span>{{job.job_type }}</span></span>
                                            <div class="job-type">
                                                <ul>
                                                    <li>{{ job.job_type  }}</li>
                                                    <li class="Urgent">Urgent</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="job-type job-apply">  
                                        <router-link :to="'/JobApply/'+job.id" class="btn btn-primary" >Apply Now</router-link>                             
                                        <!-- <button class="btn btn-primary">
                                            </button> -->
                                        <p>Deadline date <span>: {{job.deadline_date }}</span></p>
                                       <div class="jobs-amount">
                                        <!-- <p>3,50000 - 5,00000 <small>/ month</small></p> -->
                                       </div>
                                    </div>
                                </div>
                            </div>
                            <div class="Job-description">
                            <h3>Qualifications & Education Requirements </h3>
                            <p v-html="job.job_qualification">
                            </p>
                            
                            <h3>The Work You’ll Do: </h3>
                            <p  v-html="job.job_responsability">
                            </p>
                            
                             <h3>Age: </h3>
                            <p v-html="job.job_age_limit"></p>
                            <h3>Additional Notes: </h3>
                            <p v-html="job.job_additional_info"></p>
                            
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Service End -->
        
    </section>
</template>
<script>
import axios from "axios";
import NavigationComponent from "./common/NavigationComponent.vue";
export default {
    components: {
        NavigationComponent,
    },

    data() {
        return {
            jobObj: [],
            baseUrl: Laravel.BaseURL,
            search_keyword:""
        };
    },
    created() {
        axios
            .get(this.baseUrl + "/get_jobs")
            .then((res) => {
                this.jobObj = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    },
    methods: {
        search_job(){
            
            axios
            .get(this.baseUrl + "/get_jobs",{params:{search_keyword:this.search_keyword}})
            .then((res) => {
                this.jobObj = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
        },
    },
};
</script>
