<template>
    <section>
        <!-- Callback Start -->
        <div class="container-fluid">
            <div class="container pt-5">
                <div class="row justify-content-center">
                    <div class="col-lg-7">
                        <div
                            class="text-center mx-auto wow fadeInUp"
                            data-wow-delay="0.1s"
                            style="max-width: 600px"
                        >
                            <h3 class="display-5 mb-5 hd-t">Message Us</h3>
                        </div>
                        <div
                            class="border rounded p-4 p-sm-5 wow fadeInUp Message-body"
                            data-wow-delay="0.5s"
                        >
                            <form @submit.prevent="sendEmail()">
                                <div class="row g-3">
                                    <div class="col-sm-6">
                                        <div class="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="formFields.name"
                                                placeholder="Your Name"
                                            />
                                            <label for="name">Name</label>
                                            <span
                                                v-if="errors.name"
                                                class="alert-danger"
                                                >{{ errors.name[0] }}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-floating">
                                            <input
                                                type="email"
                                                class="form-control"
                                                placeholder="Your Email"
                                                v-model="formFields.email"
                                            />
                                            <label for="mail">Email</label>
                                            <span
                                                v-if="errors.email"
                                                class="alert-danger"
                                                >{{ errors.email[0] }}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="form-floating">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="formFields.subject"
                                                placeholder="Subject"
                                            />
                                            <label for="subject">Subject</label>
                                            <span
                                                v-if="errors.subject"
                                                class="alert-danger"
                                                >{{ errors.subject[0] }}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-floating">
                                            <textarea
                                                class="form-control"
                                                placeholder="Leave a message here"
                                                v-model="
                                                    formFields.message_content
                                                "
                                                style="height: 100px"
                                            ></textarea>
                                            <label for="message_content"
                                                >Message</label
                                            >
                                            <span
                                                v-if="errors.message_content"
                                                class="alert-danger"
                                                >{{
                                                    errors.message_content[0]
                                                }}</span
                                            >
                                        </div>
                                    </div>
                                    <div class="col-12 mt-5">
                                        <button
                                            class="btn py-3 send-btn"
                                            type="submit"
                                            :disabled="formSubmitting"
                                        >
                                            {{ submitButtonText }}
                                        </button>
                                        <p
                                            :class="
                                                error_flag
                                                    ? 'alert-danger'
                                                    : 'alert-success'
                                            "
                                        >
                                            {{ sent_status }}
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Callback End -->
    </section>
</template>

<script>
export default {
    data() {
        return {
            formFields: {
                name: "",
                email: "",
                subject: "",
                message_content: "",
            },
            baseUrl: Laravel.BaseURL,
            formSubmitting: false,
            submitButtonText: "Send Email",
            sent_status: "",
            errors: {},
            error_flag : false,
        };
    },
    methods: {
        sendEmail() {
            this.formSubmitting = true;
            this.submitButtonText = "Sending...";
            let formData = new FormData();
            formData = this.customAppendFormData(formData);
            this.sent_status="";
            axios
                .post(this.baseUrl + "/api/sendEmail", formData)
                .then((response) => {
                    if (response.data.errors) {
                        this.formSubmitting = false;
                        this.submitButtonText = "Send Email";
                        this.errors = {};
                        this.sent_status = response.data.errors;
                        this.error_flag = true;
                    } else {
                        this.formFields.name = "";
                        this.formFields.email = "";
                        this.formFields.subject = "";
                        this.formFields.message_content = "";
                        this.formSubmitting = true;
                        this.error_flag = false;
                        this.sent_status = "";
                        this.errors = {};
                        setInterval(() => {
                            this.formSubmitting = false;
                            this.submitButtonText = "Send Email";
                            this.sent_status = response.data.success;
                        }, 6000);
                    }
                })
                .catch((error) => {
                    this.formSubmitting = false;
                    this.submitButtonText = "Send Email";
                    this.sent_status = error;
                    this.errors = error.response.data.errors;
                    this.error_flag = true;
                });
        },
        customAppendFormData(formData) {
            let aFields = Object.keys(this.formFields);
            aFields.forEach(function (field, index) {
                formData.append(
                    field,
                    this.formFields[field] ? this.formFields[field] : ""
                );
            }, this);
            return formData;
        },
    },
};
</script>
