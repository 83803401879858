<template>
    <section>
        <!-- Carousel Start -->
        <div
            class="container-fluid p-0 mb-5 wow fadeIn main-bar"
            data-wow-delay="0.1s"
        >
            <div
                id="header-carousel"
                class="carousel slide carousel-fade"
                data-bs-ride="carousel"
            >
                <div
                    class="main-banner"
                    style="
                        background-image: url('img/news-bg.png');
                        background-size: cover;
                    "
                >
                    <div class="hd-main">
                        <div class="container">
                            <h3>News & Announcements</h3>
                            <p>
                                Stay connected, Timely headlines and critical
                                updates for your knowledge
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Carousel End -->
        <!-- Service Start -->
        <div class="container-xxl service py-5">
            <div class="container">
                <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="col-md-8">
                        <h3>Latest News</h3>
                        <!-- <p>
                            Loipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua.
                        </p> -->
                        <div class="row mt-5">
                            <div
                                class="col-md-6 new-detail"
                                v-for="newsval in news"
                                :key="newsval.id"
                            >
                                <a
                                    :href="news_event.original_url"
                                    v-show="i < 1"
                                    :data-lightbox="newsval.id"
                                    :data-title="newsval.title"
                                    v-for="(
                                        news_event, i
                                    ) in newsval.news_event_images"
                                >
                                    <img
                                        style="width: 320px; height: 200px"
                                        class="img-fluid"
                                        :src="news_event.original_url"
                                    />
                                </a>

                                <h3>{{ newsval.title }}</h3>
                                <div class="date">
                                    {{ newsval.activity_date }}
                                </div>
                                <p>{{ newsval.description }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 Useful">
                        <h4>Useful links</h4>
                        <ul>
                            <li>
                                <a
                                    class=""
                                    target="_blank"
                                    href="https://nastp.gov.pk/"
                                    >NASTP</a
                                >
                            </li>
                            <li>
                                <a
                                    class=""
                                    target="_blank"
                                    href="https://mod.gov.pk/"
                                    >Ministry of Defense</a
                                >
                            </li>
                            <li>
                                <a
                                    class=""
                                    target="_blank"
                                    href="https://www.pcsir.gov.pk/"
                                    >PCSIR</a
                                >
                            </li>
                            <li>
                                <a
                                    class=""
                                    target="_blank"
                                    href="https://www.uaar.edu.pk/"
                                    >ARID</a
                                >
                            </li>
                        </ul>
                        <h4>Announcement</h4>
                        <div
                            class="announcement"
                            v-for="announcement in announcementObj"
                            :key="announcement.id"
                        >
                            <h5>{{ announcement.title }}</h5>
                            <div class="date">
                                {{ announcement.activity_date }}
                            </div>
                            <p>{{ announcement.description }}</p>
                        </div>
                    </div>

                    <div class="clearfix"></div>

                    <div class="col-md-12">
                        <h3>
                            Latest Events. Don’t miss it.
                            <span>View more Events</span>
                        </h3>
                        <div class="row mt-4">
                            <div
                                class="col-md-4 new-event"
                                v-for="eventval in eventsObj"
                                :key="eventval.id"
                            >
                                <a
                                    :href="image_event.original_url"
                                    v-show="i < 1"
                                    :data-lightbox="eventval.id"
                                    :data-title="eventval.title"
                                    v-for="(
                                        image_event, i
                                    ) in eventval.news_event_images"
                                >
                                    <img
                                        style="width: 320px; height: 200px"
                                        class="img-fluid"
                                        :src="image_event.original_url"
                                    />
                                </a>
                                <p>{{ eventval.title }}</p>
                                <h3>{{ eventval.description }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <div
                            class="text-center mx-auto wow fadeInUp"
                            data-wow-delay="0.1s"
                        >
                            <h3 class="display-5 mb-4 hd-t">
                                Pictures Gallery
                            </h3>
                            <div class="bor-bottom"></div>
                        </div>

                        <div class="grid-wrapper">
                            <div
                                v-for="gallery in galleryObj"
                                :key="gallery.id"
                            >
                                <a
                                    :href="gallery.gallery_images.url"
                                    data-lightbox="33333"
                                    :data-title="gallery.title"
                                >
                                    <img
                                        :src="gallery.gallery_images.url"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <div
                            class="text-center mx-auto wow fadeInUp"
                            data-wow-delay="0.1s"
                        >
                            <h3 class="display-5 mb-4 hd-t">Our Videos</h3>
                            <div class="bor-bottom"></div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <!-- Button trigger modal -->
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div
                                    class="owl-carousel owl-themea owl-carousel-video"
                                >
                                    <div class="item">
                                        <div class="new-event new-event-v">
                                            <div class="Videos video-link">
                                                <img
                                                    class="img-fluid"
                                                    src="img/video-img.png"
                                                    alt=""
                                                />
                                                <div class="btnPlay">
                                                    <div
                                                        class="play"
                                                        @click="addUrl(videoUrl1,videoUrlTitle1)"
                                                    >
                                                        <img
                                                            src="img/play.png"
                                                            style="width: 80px"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <p>Artificial Intelligence</p>
                                            <h3>
                                                National Aerospace Science and
                                                Technology Park (NASTP)
                                            </h3>
                                            <span>By admin / 8 months ago</span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <!-- <div class="item"> -->
                                            <div class="new-event new-event-v">
                                                <div class="Videos video-link">
                                                    <img
                                                        class="img-fluid"
                                                        src="img/video-img2.png"
                                                        alt=""
                                                    />
                                                    <div class="btnPlay">
                                                        <div
                                                            class="play"
                                                            @click="addUrl(videoUrl2,videoUrlTitle2)"
                                                        >
                                                            <img
                                                                src="img/play.png"
                                                                style="
                                                                    width: 80px;
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>Artificial Intelligence</p>
                                                <h3>
                                                    Pakistan Council of
                                                    Scientific & Industrial
                                                    Research
                                                </h3>
                                                <span
                                                    >By admin / 8 months
                                                    ago</span
                                                >
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                    <div class="item">
                                        <!-- <div class="item"> -->
                                            <div class="new-event new-event-v">
                                                <div class="Videos video-link">
                                                    <img
                                                        class="img-fluid"
                                                        src="img/video-img3.png"
                                                        alt=""
                                                    />
                                                    <div class="btnPlay">
                                                        <div
                                                            class="play"
                                                            @click="addUrl(videoUrl3,videoUrlTitle3)"
                                                        >
                                                            <img
                                                                src="img/play.png"
                                                                style="
                                                                    width: 80px;
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>Artificial Intelligence</p>
                                                <h3>
                                                    PMAS-Arid Agriculture
                                                    University Rawalpindi
                                                </h3>
                                                <span
                                                    >By admin / 8 months
                                                    ago</span
                                                >
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                </div>

                                <VideoComponent
                                    :videoUrl="videoUrl"
                                    :videoUrlTitle="videoUrlTitle"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Service End -->
    </section>
</template>
<style>
.grid-wrapper > div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.grid-wrapper > div > a > img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
}

.grid-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;
}
.grid-wrapper .wide {
    grid-column: span 2;
}
.grid-wrapper .tall {
    grid-row: span 2;
}
.grid-wrapper .big {
    grid-column: span 2;
    grid-row: span 2;
}
</style>
<script>
// import axios from "axios";
import VideoComponent from "./VideoComponent.vue";
export default {
    components: {
        VideoComponent,
    },
    data() {
        return {
            news: [],
            eventsObj: [],
            galleryObj: [],
            announcementObj: [],
            baseUrl: Laravel.BaseURL,
            videoUrl : '',
            videoUrlTitle : '',
            videoUrl1 : 'https://www.youtube.com/embed/xHCcWjgciec',
            videoUrlTitle1 : 'NASTP and Green AI Precision Agriculture projects',
            videoUrl3 : 'https://www.youtube.com/embed/62GoElUPJ6Q',
            videoUrlTitle3 : 'Project GreenAI',
            videoUrl2 : 'https://www.youtube.com/embed/ofjZugwjo0Q',
            videoUrlTitle2 : 'PCSIR',
        };
    },
    mounted() {
        $(".owl-carousel-video").owlCarousel({
            loop: false,
            margin: 10,
            nav: true,
            responsive: {
                0: {
                    items: 1,
                },
                800: {
                    items: 2,
                },
                1000: {
                    items: 3,
                },
            },
        });
    },
    created() {
        axios
            .get(this.baseUrl + "/get_news")
            .then((res) => {
                this.news = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

        axios
            .get(this.baseUrl + "/get_events")
            .then((res) => {
                this.eventsObj = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

        axios
            .get(this.baseUrl + "/get_gallery_photos")
            .then((res) => {
                this.galleryObj = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

        axios
            .get(this.baseUrl + "/get_announcements")
            .then((res) => {
                this.announcementObj = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    },
    methods: {
        addUrl(vurl,vtitle){
            this.videoUrl = vurl;
            this.videoUrlTitle = vtitle;
            $('.modal').modal('show');
        },
    },
};

(function () {
    $(document).on("click", ".click-to-expand", function () {
        var imageSrc = $(this).parents(".image-grid").find("img").attr("src");
        $(".js-modal-image").attr("src", imageSrc);
    });
})();
const items = document.querySelectorAll(".accordion button");
function toggleAccordion() {
    const itemToggle = this.getAttribute("aria-expanded");

    for (i = 0; i < items.length; i++) {
        items[i].setAttribute("aria-expanded", "false");
    }

    if (itemToggle == "false") {
        this.setAttribute("aria-expanded", "true");
    }
}

items.forEach((item) => item.addEventListener("click", toggleAccordion));
</script>
