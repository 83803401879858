<template>
    <section>
        <!-- Callback Start -->
        <div class="container-fluid">
            <div class="container pt-5" style="padding-top: 5rem !important">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div
                            class="text-center mx-auto wow fadeInUp"
                            data-wow-delay="0.1s"
                            style="max-width: 600px"
                        >
                            <h3 class="display-5 mb-5 hd-t">Job Apply Form</h3>
                        </div>
                        <div
                            class="border rounded p-4 p-sm-5 wow fadeInUp Message-body"
                            data-wow-delay="0.5s"
                        >
                            <form @submit.prevent="saveApplyJob()">
                                <div class="row g-3">
                                    <fieldset class="row g-3">
                                        <legend>Personal Detail</legend>
                                        <!-- <label for="subject">Personal Detail</label> -->
                                        <div class="col-sm-4">
                                            <div class="form">
                                                <label for="name">Name</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="formFields.name"
                                                    placeholder="Your Name"
                                                />
                                            </div>
                                            <span
                                                v-if="errors.name"
                                                class="alert-danger"
                                                >{{ errors.name[0] }}</span
                                            >
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form">
                                                <label for="phone_no"
                                                    >Phone No</label
                                                >
                                                <input
                                                    class="form-control"
                                                    v-model="
                                                        formFields.phone_no
                                                    "
                                                    v-maska
                                                    data-maska="03##-#######"
                                                    placeholder="03##-#######"
                                                />

                                                <span
                                                    v-if="errors.phone_no"
                                                    class="alert-danger"
                                                    >{{
                                                        errors.phone_no[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form">
                                                <label for="email">Email</label>
                                                <input
                                                    type="email"
                                                    class="form-control"
                                                    v-model="formFields.email"
                                                    placeholder="Your Email"
                                                />

                                                <span
                                                    v-if="errors.email"
                                                    class="alert-danger"
                                                    >{{ errors.email[0] }}</span
                                                >
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="row g-3">
                                        <legend>Last Education Detail</legend>

                                        <div class="col-sm-4">
                                            <div class="form">
                                                <label for="degree"
                                                    >Your Degree</label
                                                >
                                                <select
                                                    class="form-control"
                                                    v-model="formFields.degree"
                                                    style="background-color: #fff;"
                                                >
                                                    <option value="Ph.D">
                                                        Ph.D
                                                    </option>
                                                    <option value="master">
                                                        Master
                                                    </option>
                                                    <option value="bachlor">
                                                        Bachelor
                                                    </option>
                                                    <option value="intermediat">
                                                        Intermediate
                                                    </option>
                                                    <option value="Matric">
                                                        Matric
                                                    </option>
                                                </select>

                                                <span
                                                    v-if="errors.degree"
                                                    class="alert-danger"
                                                    >{{
                                                        errors.degree[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form">
                                                <label for="degree_start"
                                                    >Start Date</label
                                                >
                                                <input
                                                    type="date"
                                                    class="form-control"
                                                    v-model="
                                                        formFields.degree_start
                                                    "
                                                    placeholder="Start Date"
                                                />

                                                <span
                                                    v-if="errors.degree_start"
                                                    class="alert-danger"
                                                    >{{
                                                        errors.degree_start[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form">
                                                <label for="degree_end"
                                                    >End Date</label
                                                >
                                                <input
                                                    type="date"
                                                    class="form-control"
                                                    v-model="
                                                        formFields.degree_end
                                                    "
                                                    placeholder="End Date"
                                                />

                                                <span
                                                    v-if="errors.degree_end"
                                                    class="alert-danger"
                                                    >{{
                                                        errors.degree_end[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>

                                        <div class="col-sm-4">
                                            <div class="form">
                                                <label for="degree_total_marks"
                                                    >Total Marks/CGPA</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="
                                                        formFields.degree_total_marks
                                                    "
                                                    placeholder="Total Marks/CGPA"
                                                />

                                                <span
                                                    v-if="
                                                        errors.degree_total_marks
                                                    "
                                                    class="alert-danger"
                                                    >{{
                                                        errors
                                                            .degree_total_marks[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>

                                        <div class="col-sm-4">
                                            <div class="form">
                                                <label
                                                    for="degree_obtained_marks"
                                                    >Obtained Marks/CGPA</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="
                                                        formFields.degree_obtained_marks
                                                    "
                                                    placeholder="Obtained Marks/CGPA"
                                                />

                                                <span
                                                    v-if="
                                                        errors.degree_obtained_marks
                                                    "
                                                    class="alert-danger"
                                                    >{{
                                                        errors
                                                            .degree_obtained_marks[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="row g-3">
                                        <legend>
                                            Last Work Experience Detail
                                        </legend>

                                        <div class="col-sm-4">
                                            <div class="form">
                                                <label for="exp_company"
                                                    >Company Name</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    name="exp_company"
                                                    v-model="
                                                        formFields.exp_company
                                                    "
                                                    placeholder="Company Name"
                                                />

                                                <span
                                                    v-if="errors.exp_company"
                                                    class="alert-danger"
                                                    >{{
                                                        errors.exp_company[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>

                                        <div class="col-sm-4">
                                            <div class="form">
                                                <label for="exp_start"
                                                    >Start Date</label
                                                >
                                                <input
                                                    type="date"
                                                    class="form-control"
                                                    name="exp_start"
                                                    v-model="
                                                        formFields.exp_start
                                                    "
                                                    placeholder="Start Date"
                                                />

                                                <span
                                                    v-if="errors.exp_start"
                                                    class="alert-danger"
                                                    >{{
                                                        errors.exp_start[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form">
                                                <label for="exp_end"
                                                    >End Date</label
                                                >
                                                <input
                                                    type="date"
                                                    class="form-control"
                                                    name="exp_end"
                                                    v-model="formFields.exp_end"
                                                    placeholder="End Date"
                                                />

                                                <span
                                                    v-if="errors.exp_end"
                                                    class="alert-danger"
                                                    >{{
                                                        errors.exp_end[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="row g-3">
                                        <legend>
                                            Overall Experience (in Years)
                                        </legend>

                                        <div class="col-sm-4">
                                            <div class="form">
                                                <label for="total_experience"
                                                    >Total Experience</label
                                                >
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    name="total_experience"
                                                    v-model="
                                                        formFields.total_experience
                                                    "
                                                    placeholder="Total Experience"
                                                />

                                                <span
                                                    v-if="
                                                        errors.total_experience
                                                    "
                                                    class="alert-danger"
                                                    >{{
                                                        errors
                                                            .total_experience[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="row g-3">
                                        <legend>
                                            Cover Letter and Upload CV
                                        </legend>

                                        <div class="col-12">
                                            <div class="form">
                                                
                                                <textarea
                                                    class="form-control"
                                                    placeholder="Enter Cover letter here"
                                                    id="cover_letter"
                                                    name="cover_letter"
                                                    v-model="
                                                        formFields.cover_letter
                                                    "
                                                    style="height: 100px"
                                                ></textarea>

                                                <span
                                                    v-if="errors.cover_letter"
                                                    class="alert-danger"
                                                    >{{
                                                        errors.cover_letter[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="row g-3">
                                        <legend>
                                            Upload Resume (pdf or docx)
                                        </legend>

                                        <div class="col-sm-12">
                                            <div class="form">
                                                <input
                                                    type="file"
                                                    class="form-control"
                                                    style="padding-top: 3%"
                                                    @change="uploadJobFiles"
                                                    ref="fileInput"
                                                />
                                                <span
                                                    v-if="errors.file"
                                                    class="alert-danger"
                                                    >{{ errors.file[0] }}</span
                                                >
                                            </div>
                                        </div>
                                    </fieldset>

                                    <div class="col-12 mt-5">
                                        <button
                                            class="btn py-3 send-btn"
                                            type="submit"
                                            :disabled="formSubmitting"
                                        >
                                            {{ submitButtonText }}
                                        </button>
                                        <p class="alert-danger">
                                            {{ sent_status }}
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Callback End -->
    </section>
</template>
<style scoped>
fieldset {
    background-color: #eeefff;
    padding-bottom: 24px;
    border-radius: 10px;
}
legend {
    display: block;
    padding-left: 2px;
    padding-right: 2px;
    border: none;
}
</style>

<script>
export default {
    data() {
        return {
            selectedFile: null,
            formFields: {
                id: this.$route.params.id,
                name: "",
                phone_no: "",
                email: "",
                degree: "",
                degree_start: "",
                degree_end: "",
                degree_total_marks: "",
                degree_obtained_marks: "",
                exp_company: "",
                exp_start: "",
                exp_end: "",
                total_experience: "",
                cover_letter: "",
            },
            baseUrl: Laravel.BaseURL,
            formSubmitting: false,
            submitButtonText: "Submit",
            sent_status: "",
            errors: {},
        };
    },
    methods: {
        uploadJobFiles(event) {
            //this.selectedFile = event.target.files[0];
            this.selectedFile = this.$refs.fileInput.files[0];
        },
        saveApplyJob() {
            this.formSubmitting = true;
            this.submitButtonText = "Submitting...";
            let formData = new FormData();
            formData = this.customAppendFormData(formData);
            //debugger;
            // const formData = new FormData();
            // formData.append('file', this.selectedFile);
            axios
                .post(this.baseUrl + "/ApplyJob", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    //debugger;
                    //this.$router.push("/Jobs");
                    // if (!response.data.status) {
                    //     this.errors = response.data.errors;
                    //     this.formSubmitting = false;
                    //     this.submitButtonText = "Submit";
                    //     this.sent_status = "Please fill all required fields";
                    // } else {
                    this.formFields.name = "";
                    this.formFields.phone_no = "";
                    this.formFields.email = "";
                    this.formFields.degree = "";
                    this.formFields.degree_start = "";
                    this.formFields.degree_end = "";
                    this.formFields.degree_total_marks = "";
                    this.formFields.degree_obtained_marks = "";
                    this.formFields.exp_company = "";
                    this.formFields.exp_start = "";
                    this.formFields.exp_end = "";
                    this.formFields.total_experience = "";
                    this.formFields.cover_letter = "";
                    this.formFields.file = "";
                    this.$refs.fileInput.value = "";
                    this.formSubmitting = true;
                    this.submitButtonText = "Submit Successfully";
                    //debugger;
                    // setInterval(() => {
                    this.formSubmitting = false;
                    this.sent_status = response.data.success;
                    this.submitButtonText = "Submit";
                    // }, 6000);
                    this.errors = {};
                    //alert(this.sent_status+'=Your job application received');
                    Swal.fire({
                        icon: "success",
                        title: "Success!",
                        text: this.sent_status,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "OK",
                    }).then((result) => {
                        //if (result.isConfirmed) {
                        //this.$router.push('/farmers-details/' + this.formData.farmer_id);
                        //}
                    });

                    this.$router.push("/Jobs");
                    // }
                    //console.log(response.data.message);
                })
                .catch((errorss) => {
                    //console.log(errorss);
                    //this.$router.push('/Jobs');
                    // console.log('zahid');
                    // console.log(errorss.response.data.errors);
                    //this.errors = {};
                    this.formSubmitting = false;
                    this.submitButtonText = "Submit";
                    this.sent_status = "Please fill all required fields";
                    this.errors = errorss.response.data.errors;
                });
        },
        customAppendFormData(formData) {
            let aFields = Object.keys(this.formFields);
            aFields.forEach(function (field, index) {
                formData.append(
                    field,
                    this.formFields[field] ? this.formFields[field] : ""
                );
            }, this);
            //if (this.formFields.files) {
            //for (var i = 0; i < this.formFields.files.length; i++) {
            //let file = this.formFields.files[i];
            formData.append("file", this.selectedFile);
            //}
            //}
            return formData;
        },
    },
};
</script>
