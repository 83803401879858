<template>
    <section>
        <!--  Start -->
        <div class="container-fluid py-5">
            <div class="container pt-5">
                <div class="row g-4 align-items-end- mb-4">
                    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <img class="img-fluid" src="img/about2.jpg">
                    </div>
                    <div class="col-lg-6 wow fadeInUp overwiew display-center" data-wow-delay="0.3s" >
                        <h3 class="display-5 mb-4 hd-t">Key Achievements So Far</h3>
                        <ul class="Achievements">
                            <li>
                                <p>
                                Design & Development of First GreenCabin at Pir Mehr Ali Shah
                                – Arid Agriculture University, Rawalpindi
                            </p>
                                  </li>
                            <li>
                                <p>Design, Import and Installation of 02 out of 03 Biopesticide /
                                    Biofertilizer Plants at PCSIR Labs, Lahore</p>
                            </li>
                            <li>  
                                 <p>Design & Development of First Prototype Ground Penetrating
                                    Radar at NASTP, Rawalpindi </p>
                            </li>
                            <li>
                                <p>Publication of Inception Report of National Precision Agriculture
                                    Blueprint Study by SDPI, Islamabad</p>
                            </li>
                        </ul>
                       
                     
                  </div>
                   
                 
                </div>
             
            </div>
        </div>
        <!--  End -->
    </section>
</template>

