<template>
    <section>
        <!-- Carousel Start -->
        <div
            class="container-fluid p-0 mb-5 wow fadeIn main-bar"
            data-wow-delay="0.1s"
        >
            <div
                id="header-carousel"
                class="carousel slide carousel-fade"
                data-bs-ride="carousel"
            >
                <div
                    class="main-banner"
                    style="
                        background-image: url('img/faq.png');
                        background-size: cover;
                    "
                >
                    <div class="Support">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-7">
                                    <p>Support</p>
                                    <h3>FAQ's about GreenAi</h3>
                                    <div class="search">
                                        <i class="fa fa-search"></i>
                                        <input
                                            type="text"
                                            class="form-control in-search"
                                            placeholder="Search"
                                            v-model="search_keyword"
                                            v-on:keyup="search_faq"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-md-5 faq-d d-flex align-items-center"
                                >
                                    <p>
                                        Need something cleared up? Here are our
                                        most frequently asked questions.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Carousel End -->

        <!-- Service Start -->
        <div class="container-xxl service py-5">
            <div class="container">
                <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="col-md-3">
                        <h2>General FAQs</h2>
                        <!-- <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                        </p> -->
                    </div>
                    <div class="col-md-9">
                        <div class="container-">
                            <div class="accordion">
                                <div
                                    class="accordion-item"
                                    v-for="(faq, i) in faqObj"
                                    :key="faq.id"
                                    @click="toggleAccordion(i)"
                                >
                                    <button
                                        :id="['accordion-button-' + faq.id]"
                                        :aria-expanded="expanded[i]"
                                    >
                                        <span class="accordion-title">{{
                                            faq.question
                                        }}</span>
                                        <span
                                            class="icon"
                                            :aria-hidden="expanded[i]"
                                        ></span>
                                    </button>
                                    <div class="accordion-content">
                                        <p>
                                            {{ faq.answer }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Service End -->
    </section>
</template>
<script>
export default {
    data() {
        return {
            faqObj: [],
            baseUrl: Laravel.BaseURL,
            expanded: [],
            search_keyword:""
        };
    },
    created() {
        axios
            .get(this.baseUrl + "/get_faqs")
            .then((res) => {
                this.faqObj = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    },
    methods: {
        toggleAccordion(index) {
            this.faqObj.forEach((item, i) => {
                if (i === index) {
                    this.expanded[i] = true;
                } else {
                    this.expanded[i] = false;
                }
            });
        },
        search_faq(){
            axios
            .get(this.baseUrl + "/get_faqs",{params:{search_keyword:this.search_keyword}})
            .then((res) => {
                this.faqObj = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

        },
    },
    mounted() {
        this.toggleAccordion(0);
    },
};
</script>
