<template>
    <section>
        <!-- Navbar Start -->
        <div
            class="container-fluid fixed-top px-0 wow fadeIn"
            data-wow-delay="0.1s"
        >
            <nav
                class="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-4 wow fadeIn bg-white"
                data-wow-delay="0.1s"
            >
                <router-link to="/" class="nav-item nav-link"
                    ><img class="logo" src="img/logo.png" alt=""
                /></router-link>
                <button
                    type="button"
                    class="navbar-toggler me-4"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse"
                    v-on:click="toggleSideBar()"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div
                    class="collapse navbar-collapse"
                    id="navbarCollapse"
                    v-show="isOpen"
                >
                    <div class="navbar-nav ms-auto p-4 p-lg-0">
                        <router-link to="/" class="nav-item nav-link" v-on:click="toggleSideBar()"
                            >Home</router-link
                        >
                        <router-link
                            to="/ProjectComponents"
                            class="nav-item nav-link"
                            v-on:click="toggleSideBar()"
                            >Project Components</router-link
                        >
                        <router-link to="/AboutUs" class="nav-item nav-link" v-on:click="toggleSideBar()"
                            >About Us</router-link
                        >
                        <router-link to="/Jobs" class="nav-item nav-link" v-on:click="toggleSideBar()"
                            >Jobs</router-link
                        >
                        <router-link
                            to="/Publications"
                            class="nav-item nav-link" v-on:click="toggleSideBar()"
                            >Publications</router-link
                        >
                        <router-link to="/News" class="nav-item nav-link" v-on:click="toggleSideBar()"
                            >News & Events</router-link
                        >
                        <router-link to="/FAQs" class="nav-item nav-link" v-on:click="toggleSideBar()"
                            >FAQ’s</router-link
                        >
                        <router-link to="/Contact" class="nav-item nav-link" v-on:click="toggleSideBar()"
                            >Contact</router-link
                        >
                        <!-- <a href="Contact.html" class="nav-item nav-link">Contact</a> -->
                    </div>
                    <a
                        style="display: none"
                        href="/login"
                        class="btn btn-primary px-4 animated slideInDown Login"
                        >Login or Register</a
                    >
                </div>
            </nav>
        </div>
        <!-- Navbar End -->
    </section>
</template>

<script>
// import axios from "axios";
export default {
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        toggleSideBar() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>
