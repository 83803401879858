<template>
    <section>
        <CarouselComponent></CarouselComponent>
        <AboutUsComponent></AboutUsComponent>
        <ProjectComponent></ProjectComponent>
        <PartnerComponent></PartnerComponent>
        <MinistryComponent></MinistryComponent>
        <AchievementComponent></AchievementComponent>
        <BecomePartnetComponent></BecomePartnetComponent> <!-- Hiring also here -->
        <MessageUsComponent></MessageUsComponent>
    </section>
</template>
<script>
import axios from "axios";
import CarouselComponent from "./CarouselComponent.vue";
import AboutUsComponent from "./AboutUsComponent.vue";
import ContactComponent from "./ContactComponent.vue";
import ProjectComponent from './ProjectComponent.vue'
import PartnerComponent from "./PartnerComponent.vue";
import AchievementComponent from "./AchievementsComponent.vue";
import MessageUsComponent from './MessageUsComponent.vue'
import BecomePartnetComponent from './BPartnerComponent.vue'
import MinistryComponent from './MinistryComponent.vue'
export default {
    components: {
        CarouselComponent,
        AboutUsComponent,
        ProjectComponent,
        PartnerComponent,
        AchievementComponent,
        MessageUsComponent,
        BecomePartnetComponent,
        MinistryComponent,
        ContactComponent,
    },

    data() {
        return {
            baseUrl: Laravel.BaseURL + "/login",
            baseUrlP: Laravel.BaseURL + "/#/ProjectComponents",
        };
    },
    methods: {
        UserLogout() {
            alert(this.baseUrl);
            // axios
            //         .post(this.baseUrl + "/logout")
            //         .then((response) => {
            //             console.log(response);
            //              window.location.replace(this.baseUrl) + '/login';
            //         })
            //         .catch((error) => {
            //             window.location.replace(this.baseUrl);
            //         });
            //window.location.href = '/logout';
        },
    },
};
</script>
