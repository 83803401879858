<template>
    <section>
        <!-- Carousel Start -->
        <div
            class="container-fluid p-0 mb-5 wow fadeIn main-bar"
            data-wow-delay="0.1s"
        >
            <div
                id="header-carousel"
                class="carousel slide carousel-fade"
                data-bs-ride="carousel"
            >
                <div
                    class="main-banner"
                    style="
                        background-image: url('img/Publications-bg.png');
                        background-size: cover;
                    "
                >
                    <div class="hd-main">
                        <div class="container">
                            <h3>Publications & Procurements</h3>
                            <p>
                                Green AI refers to the use of AI technologies to
                                reduce the environmental impact of human
                                activities. It involves designing and developing
                                algorithms, models, and systems that are
                                environmentally friendly and sustainable.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Carousel End -->
        <!-- Service Start -->
        <div class="container-xxl service py-5">
            <div class="container">
                <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                    <h3>Latest Publications</h3>
                    <div
                        class="col-md-3"
                        v-for="product in products"
                        :key="product.id"
                    >
                        <div class="card p-3">
                            <img src="img/book.jpg" alt="" />
                            <p>{{ product.activity_date }}</p>
                            <h3>{{ product.title }}</h3>
                            <div class="d-flex justify-content-between pt-3">
                                <a
                                    target="_new"
                                    v-if="product.publication_document"
                                    :href="product.publication_document.url"
                                >
                                    Download</a
                                >
                                <!-- <span>Read more</span> -->
                            </div>
                        </div>
                    </div>
                    
                    <div class="card">
                    <a
                          style="text-align: center;"          
                        target="_blank"
                        href="https://www.ppra.org.pk/Rules.asp"
                        >PPRA Rules</a
                    >
                    <div class="card-block">
                    
                        <div class="table-responsive dt-responsive">
                            <table 
                                ref="dataTable"
                                class="table table-striped table-bordered nowrap"
                            >
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Tender No</th>
                                        <th>Contract No</th>
                                        <th>Cont Date</th>
                                        <th>Procuring Agency</th>
                                        <th>Vendor</th>
                                        <th>Tender Name / Contract Name</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(tender, i) in tenders"
                                        :key="tender.id"
                                    >
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ tender.tender_no }}</td>
                                        <td>{{ tender.contract_no }}</td>
                                        <td>{{ tender.contract_date }}</td>
                                        <td>{{ tender.procuring_agency }}</td>
                                        <td>{{ tender.vendor }}</td>
                                        <td>
                                            {{ tender.tender_contract_name }}
                                        </td>
                                        <td>{{ tender.remarks }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Service End -->
    </section>
</template>

<script>
// import axios from "axios";
export default {
    data() {
        return {
            products: [],
            tenders: [],
            baseUrl: Laravel.BaseURL,
        };
    },
    mounted() {
      this.fetchTenders();
    },
    created() {
        axios
            .get(this.baseUrl + "/get_project_publication")
            .then((res) => {
                this.products = res.data;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    },
    methods: {
        fetchTenders() {
            axios
                .get(this.baseUrl + "/get_tenders")
                .then((res) => {
                    this.tenders = res.data;
                    setTimeout(() => {
                        this.initializeDataTable();
                    }, 1000)
                    //
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        },
        initializeDataTable() {
            $(this.$refs.dataTable).DataTable().destroy();
            $(this.$refs.dataTable).DataTable({
                paging: true,
                pageLength: 10,
                searching: true,
            });
        },
    },
};
</script>