<template>
  <div class="py-12">
     <div v-if="successMessage" class="alert alert-success">
      {{ successMessage }}
    </div>
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg">
        <div class="p-6 bg-white border-b border-gray-200" style="margin: 10px">
          <div class="container-lg">
            <div class="table-responsive">
              <div class="table-wrapper">
                <div class="table-title">
                  <div class="row">
                    <div class="col-sm-8">
                      <h2><b>Applied Jobs</b></h2>
                    </div>
                    <div class="col-sm-4" style="display:flex">
                      <form>
                        <div class="input-group" >
                          <div class="form-outline">
                            <input
                              v-model="searchStatus"
                              type="search"
                              name="status"
                              class="form-control"
                              placeholder="Search by Status"
                            />
                          </div>
                          <button type="submit" class="btn btn-primary" style="line-height: 2rem;height: 2.3rem;">
                            <i class="fas fa-search" ></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone No</th>
                      <th>Cover Letter</th>
                      <th>Resume</th>
                      <th>Applied Job</th>
                      <th>Applied Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="filteredJob in filteredAppliedJobs" :key="filteredJob.id" >
                      <td>{{ filteredJob.name }}</td>
                      <td>{{ filteredJob.email }}</td>
                      <td>{{ filteredJob.contact }}</td>                      
                      <td>{{ filteredJob.cover_letter }}</td>
                      <td>
                     <a :href="getResumeUrl(filteredJob.resume)" alt="image not found" 
                     style="width:50px;height:50px" target="_blank">
                        {{ filteredJob.resume }}
                          </a>
                           </td>
                      <td>{{ filteredJob.job ? filteredJob.job.title : '' }}</td>
                      <td>{{ filteredJob.applied_at }}</td>
                      <td>
                        <form >
                          <select class="select" v-model="filteredJob.status" @change="updateStatus(filteredJob)">
                            <option value="selected">Selected</option>
                            <option value="rejected">Rejected</option>
                            <option value="pending">Pending</option>
                          </select>
                        </form>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
        successMessage: '',
      appliedjobs: [], // Populate this array with your data
      searchStatus: '',
      baseUrl:'http://project.green_ai/storage/uploads/'
    };
  },
     computed: {
    filteredAppliedJobs() {
      if (!this.searchStatus) {
        return this.appliedjobs; // Return all jobs if no search status
      }
      return this.appliedjobs.filter(job =>
        job.status.toLowerCase().includes(this.searchStatus.toLowerCase())
      );
    },
  },
    created() {
     this.fetchAppliedJobs();
  },
    methods: {
        async fetchAppliedJobs() {
            axios.get(`http://project.green_ai/admin/AppliedJobs#/`).then((res) => {
                this.appliedjobs = res.data;
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
      },
      updateStatus(filteredJob) {
        axios.post(`http://project.green_ai/admin/AppliedJobs/update/${filteredJob.id}`, { status: filteredJob.status })
        .then(response => {
          this.successMessage = response.data.message;
        })
        .catch(error => {
          console.error('Error updating status:', error);
        });
          // axios.post(`http://project.green_ai/admin/AppliedJobs#/`).then((res) => {
          //       this.appliedjobs = res.data;
          //   }).catch((error) => {
          //       console.error('Error fetching data:', error);
          //   });
      //console.log(appliedjob.status);
      },
      getResumeUrl(resumeFileName) {
      return this.baseUrl + resumeFileName;
      },
    
   
  },
      
    
    }
  ;
</script>