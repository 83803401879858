<template>
        <div class="container" style="margin-top:20px">
  <div v-if="successMessage" class="alert alert-success">
      {{ successMessage }}
    </div>
  <form @submit.prevent="submitForm" enctype="multipart/form-data">
    <br>
    <div class="custom-file pmd-custom-file-outline col-md-10">
      <input type="file" name='image' ref="imageInput" @change="handleFileChange" class="custom-file-input" id="customOutlineFile">
    </div><br><br>
    <div class="form-row mt-2">
      <div class="form-group pmd-textfield pmd-textfield-floating-label col-md-10">
        <input v-model="title" name="title" type="text" id="form-layout-address1" class="form-control" placeholder="Title">
      </div>
    </div><br>
    
      <div class="pmd-textfield pmd-textfield-filled pl-0 col-md-10">
        <textarea v-model="description" name="description" rows="5" placeholder="Description" class="form-control" aria-label="With textarea"></textarea>
      </div>
    <br>
    <button type="submit" class="btn pmd-ripple-effect btn-primary col-md-10 mt-2">Add</button>
  </form>
  </div>
</template>

<script>
export default {
  data() {
        return {
        successMessage:'',
      selectedFileName: '',
      title: '',
          description: '',
      image: null
    };
  },
  methods: {
   handleFileChange(event) {
         const file = event.target.files[0];
       this.selectedFileName = file ? file.name : '';
      this.image = file;
    },
    submitForm() {
              const formData = new FormData();
      formData.append('title', this.title);
      formData.append('description', this.description);
    if (this.image) {
        formData.append('image', this.image, this.image.name); // Append the image with its name
      }
       axios.post(`http://project.green_ai/admin/ConsortiumPartner/store`,formData, {
    headers: {
      'Content-Type': 'multipart/form-data' // Set content type for FormData
    }
  })
        .then(response => {
          this.successMessage = response.data.message;
             this.title = '';
          this.description = '';
          this.selectedFileName = null;
          // Reset the input file element
          this.$refs.imageInput.value = '';
        })
        .catch(error => {
          console.error('Error updating status:', error);
        });
    }
  }
};
</script>

<style>
/* Your styles here */
</style>
